export const BUS_EVENTS = {
  SHOW_ALERT: 'SHOW_ALERT',
  START_NEW_CONVERSATION: 'START_NEW_CONVERSATION',
  FOCUS_CUSTOM_ATTRIBUTE: 'FOCUS_CUSTOM_ATTRIBUTE',
  SCROLL_TO_MESSAGE: 'SCROLL_TO_MESSAGE',
  MESSAGE_SENT: 'MESSAGE_SENT',
  FETCH_LABEL_SUGGESTIONS: 'FETCH_LABEL_SUGGESTIONS',
  TOGGLE_SIDEMENU: 'TOGGLE_SIDEMENU',
  ON_MESSAGE_LIST_SCROLL: 'ON_MESSAGE_LIST_SCROLL',
  WEBSOCKET_DISCONNECT: 'WEBSOCKET_DISCONNECT',
  TOGGLE_REPLY_TO_MESSAGE: 'TOGGLE_REPLY_TO_MESSAGE',
  SHOW_TOAST: 'newToastMessage',
  NEW_CONVERSATION_MODAL: 'newConversationModal',
  INSERT_INTO_RICH_EDITOR: 'insertIntoRichEditor',
};
